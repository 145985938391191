// noinspection ES6UnusedImports
import mainCss from '../sass/main.scss'; //do not remove!
import UIkit from 'uikit';


window.UIkit = UIkit


window.UIkitIcons = window.UIkitIcons || [];

let icons = {



}

window.UIkit.icon.add(icons);

window.UIkitIcons = window.UIkitIcons.concat(Object.keys(icons))

